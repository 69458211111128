<template>
  <Teleport
    to="#teleports"
    :disabled="noTeleport"
  >
    <div
      v-if="visible"
      class="zmodal-wrapper"
      v-bind="$attrs"
    >
      <Transition
        appear
        name="zmodal"
        @after-leave="handleAfterLeave"
      >
        <div
          v-show="showContent"
          :id="id"
          ref="modalEl"
          class="zmodal"
        >
          <UseFocusTrap
            :options="{ immediate: true, initialFocus: () => contentEl, clickOutsideDeactivates: closeOnBackdrop, escapeDeactivates: closeOnEscape }"
            :class="dialogClasses"
          >
            <div
              ref="contentEl"
              class="zmodal-content"
              tabindex="-1"
            >
              <div
                v-if="!hideHeader"
                :class="headerClasses"
              >
                <slot name="header">
                  <slot name="title">
                    <h5 class="zmodal-title">
                      {{ title }}
                    </h5>
                  </slot>
                  <slot name="header-close">
                    <ZClose
                      v-if="!hideHeaderClose"
                      class="zmodal-btn-close"
                      @click="hide('close')"
                    />
                  </slot>
                </slot>
              </div>

              <div :class="bodyClasses">
                <slot />
              </div>

              <div
                v-if="!hideFooter && $slots.footer"
                :class="footerClasses"
              >
                <slot name="footer" />
              </div>
            </div>
          </UseFocusTrap>
        </div>
      </Transition>

      <!-- fixed backdrop -->
      <Transition
        appear
        name="fade"
      >
        <div
          v-show="showContent"
          class="zmodal-backdrop"
        />
      </Transition>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { useScrollLock } from '@vueuse/core'
import type { ModalHideTrigger, ModalSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  noTeleport?: boolean
  title?: string
  hideHeader?: boolean
  hideFooter?: boolean
  size?: ModalSize
  id?: string
  centered?: boolean
  footerSpaceBetween?: boolean
  scrollable?: boolean
  noCloseOnEsc?: boolean
  noCloseOnBackdrop?: boolean
  hideHeaderClose?: boolean
  padded?: boolean
}>(), {
  size: 'md',
})

const emit = defineEmits<{
  shown: []
  hide: [trigger: { trigger: ModalHideTrigger }]
  hidden: []
}>()

const visible = defineModel<boolean>('visible', { required: true })

defineOptions({
  inheritAttrs: false,
})

const showContent = ref(true)

const contentEl = ref<HTMLElement>()

const isLocked = useScrollLock(document, visible.value)

watch(visible, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    isLocked.value = newValue
  }

  if (newValue) {
    showContent.value = true
    emit('shown')
  }
}, { immediate: true })

onBeforeUnmount(() => {
  if (visible.value) {
    visible.value = false
  }
})

function hide(trigger: ModalHideTrigger) {
  if (!visible.value) {
    return
  }

  emit('hide', { trigger })
  showContent.value = false
}

function handleAfterLeave() {
  visible.value = false
  emit('hidden')
}

function closeOnBackdrop() {
  if (props.noCloseOnBackdrop) return false

  hide('clickoutside')
  return true
}

function closeOnEscape() {
  if (props.noCloseOnEsc) return false

  hide('esc')
  return true
}

const dialogClasses = computed(() => {
  return [
    'zmodal-dialog',
    `zmodal-${props.size}`,
    {
      'zmodal-dialog-scrollable': props.scrollable,
      'zmodal-dialog-centered': props.centered,
    },
  ]
})

const headerClasses = computed(() => {
  return [
    'zmodal-header',
    {
      'zmodal-header-padded': props.padded,
    },
  ]
})

const bodyClasses = computed(() => {
  return [
    'zmodal-body',
    {
      'zmodal-body-padded': props.padded,
      'zmodal-body-no-header': props.hideHeader,
      'zmodal-body-no-footer': props.hideFooter,
    },
  ]
})

const footerClasses = computed(() => {
  return [
    'zmodal-footer',
    {
      'zmodal-footer-padded': props.padded,
      'zmodal-footer-between': props.footerSpaceBetween,
    },
  ]
})
</script>
